import React, {Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import moment from 'moment';
import {useOutlet} from 'reconnect.js';
import qs from 'query-string';
import BackButton from '../../Components/BackButton';
import {navigate} from 'gatsby';
import Row from '../../Widgets/Row';
import Block from '../../Widgets/Block';
import Button from '../../Widgets/Button';
import DeleteRoleDialog from '../../Dialogs/DeleteRoleDialog';
import {Refresh} from '@styled-icons/boxicons-regular/Refresh';
import Tree from '../../Widgets/Tree';
const appConfig = require('../../data.json');

export default function EditRolePage({location}) {
  const {id: roleID} = qs.parse(location.search);
  const [role, setRole] = useState([]);
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [openDialog, setOpenDialog] = useState(false);
  const [roleAccessMenu] = useOutlet('roleAccessMenu');
  const [treeData, setTreeData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updateData, setUpdateData] = useState({
    name: '',
    comment: '',
    menus: [],
    isAmountVisible: false,
  });

  const fetchRoleDetail = useCallback(async () => {
    try {
      setLoading(true);
      let res = await actions.fetchRoleDetail(roleID);
      setRole(res);
      setUpdateData({
        name: res.name,
        comment: res.comment,
        menus: res.menus,
        isAmountVisible: res.is_amount_visible,
      });
    } catch (err) {
      console.warn(err);
    }
    setLoading(false);
  }, [roleID, actions.fetchRoleDetail]);

  const deleteRoleViaID = async () => {
    try {
      setLoading(true);
      let res = await actions.deleteRoleViaID(roleID);
      Ant.message.success('刪除角色成功');
      navigate('/role/');
    } catch (err) {
      console.warn(err);
      Ant.message.error('刪除失敗');
    }
    setLoading(false);
  };

  const convertSidebarToTreeData = (data = []) => {
    if (!data) {
      return;
    }
    return data.map((item) => {
      const newItem = {
        key: Number(item.id),
        title: item.title,
      };
      if (item.children && item.children.length > 0) {
        newItem.children = convertSidebarToTreeData(item.children);
      }
      return newItem;
    });
  };

  const handleCheckChange = (selectedKeys) => {
    setUpdateData({
      ...updateData,
      menus: selectedKeys,
    });
  };

  const confirmUpdateRole = async () => {
    try {
      setLoading(true);
      const {name, comment, menus, isAmountVisible} = updateData;
      if (!name) {
        Ant.message.error('請填寫角色名稱');
        setLoading(false);
        return false;
      }
      await actions.updateRole({
        id: roleID,
        name,
        comment,
        menus,
        is_amount_visible: isAmountVisible,
      });
      Ant.message.success('編輯角色成功');
      setUpdateData({name: '', comment: '', menus: []});
      setIsEditMode(false);
      fetchRoleDetail();
    } catch (err) {
      console.warn(err);
      Ant.message.error('編輯失敗');
    }
    setLoading(false);
  };

  const handleCancleEdit = () => {
    setUpdateData({name: '', comment: '', menus: []});
    setIsEditMode(false);
  };

  useEffect(() => {
    fetchRoleDetail();
  }, [fetchRoleDetail]);

  useEffect(() => {
    if (roleAccessMenu) {
      setTreeData(convertSidebarToTreeData(roleAccessMenu));
    } else {
      setTreeData([]);
    }
  }, [roleAccessMenu]);

  if (!role) {
    return (
      <Wrapper>
        <BackButton />
        <h2>找不到此角色資料</h2>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Row style={{marginBottom: 20}}>
        <BackButton />
        <div style={{flex: 1}} />
        {isEditMode ? (
          <Fragment>
            <StyledButton onClick={() => handleCancleEdit()}>取消</StyledButton>
            <StyledButton onClick={confirmUpdateRole}>確認</StyledButton>
          </Fragment>
        ) : (
          <Fragment>
            <StyledButton type="primary" onClick={() => setIsEditMode(true)}>
              編輯角色
            </StyledButton>
            <StyledButton type="danger" onClick={() => setOpenDialog(true)}>
              刪除角色
            </StyledButton>
            <Button
              shape="circle"
              style={{marginLeft: 8, border: 0}}
              onClick={fetchRoleDetail}
              icon={<Refresh color={appConfig.colors.main} size={26} />}
            />
          </Fragment>
        )}
      </Row>
      <Row style={{marginBottom: 20}}>
        <Block>
          <h2>角色資訊</h2>
          <div>
            <div className="row">
              <h4>會員編號</h4>
              <div># {role.id}</div>
            </div>
            <div className="row">
              <h4>角色名稱</h4>
              {isEditMode ? (
                <Ant.Input
                  value={updateData.name}
                  type="text"
                  onChange={(e) =>
                    setUpdateData({...updateData, name: e.target.value})
                  }
                />
              ) : (
                <div>{role.name}</div>
              )}
            </div>
            <div className="row">
              <h4>角色備註</h4>
              {isEditMode ? (
                <Ant.Input
                  value={updateData.comment}
                  type="text"
                  onChange={(e) =>
                    setUpdateData({...updateData, comment: e.target.value})
                  }
                />
              ) : (
                <div>{role.comment}</div>
              )}
            </div>
            <div className="row">
              <h4>建立時間</h4>
              <div>{moment(role.created_at).format('YYYY-MM-DD HH:mm')}</div>
            </div>
            <div className="row">
              <h4>最後更新時間</h4>
              <div>{moment(role.updated_at).format('YYYY-MM-DD HH:mm')}</div>
            </div>
          </div>
        </Block>
      </Row>
      <Ant.Row gutter={[20, 20]}>
        <Ant.Col span={12}>
          <Block>
            <h2>選單權限</h2>
            <Tree
              source={treeData}
              onCheckChange={handleCheckChange}
              initialCheckedKeys={role.menus}
              readOnly={!isEditMode}
            />
          </Block>
        </Ant.Col>
        <Ant.Col span={12}>
          <Block>
            <h2>特殊權限</h2>
            <Row>
              <Ant.Checkbox
                onChange={(e) =>
                  setUpdateData({
                    ...updateData,
                    isAmountVisible: e.target.checked,
                  })
                }
                checked={updateData.isAmountVisible}
                style={{
                  marginRight: '10px',
                }}
                disabled={!isEditMode}
              />
              <h4>可否查看訂單金額</h4>
            </Row>
          </Block>
        </Ant.Col>
      </Ant.Row>

      <DeleteRoleDialog
        visible={openDialog}
        onConfirm={() => deleteRoleViaID()}
        onClose={() => setOpenDialog(false)}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }
`;
